<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 终身学习导师表格
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-if="page === true">
      <div class="handle-box">
        <el-select v-model="orgId" placeholder="请选择机构" style="margin-right: 30px">
          <el-option
              v-for="item in orgData"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId">
          </el-option>
        </el-select>
        <el-input v-model="parents.parentsName" placeholder="搜索终身学习导师昵称" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
          :data="parentsData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="parentsName" label="终身学习导师昵称"></el-table-column>
        <el-table-column prop="parentsIcon" label="头像">
          <template v-slot="scope">
            <img :src="scope.row.parentsIcon" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="所属机构"></el-table-column>
        <el-table-column prop="parentsTime" label="注册时间"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-user-solid"
                @click="showClick(scope.$index, scope.row)"
            >查看邀请用户
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

    <div class="container" v-if="page === false">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-back" @click="back">后 退</el-button>
      </div>
      <el-table
          :data="parentsData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column
            type="index"
            width="50"
            label="ID"
            align="center">
        </el-table-column>
        <el-table-column prop="parentsName" label="智慧父母昵称"></el-table-column>
        <el-table-column prop="parentsIcon" label="头像">
          <template v-slot="scope">
            <img :src="scope.row.parentsIcon" :preview-src-list="[scope.row.thumb]" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="所属机构"></el-table-column>
        <el-table-column prop="parentsTime" label="注册时间"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-user-solid"
                @click="showClick(scope.$index, scope.row)"
            >查看邀请用户
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change="handleCurrentChange"
                     :current-page="pageNum"
                     :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="totalCount">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Parents",
  inject: ['reload'],
  data() {
    return {
      page: true,

      orgData: [],

      parentsData: [],

      orgId: '',
      orgName: '',

      parents: {
        parentsId: '',
        parentsName: '',
        parentsIcon: '',
        parentsIdentity: '',
        clerkId: '',
        inviterParentsId: '',
        orgId: '',
        parentsTime: '',
        parentsPhone: '',
      },

      // 默认显示第几页
      pageNum: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      pageSize: 10,

      loading: true
    }
  },
  created() {
    // 机构用户登录仅显示本机构信息
    let orgId = localStorage.getItem("orgId")
    if (orgId != null && orgId != '' && orgId != "null") {
      this.$axios({
        method: "get",
        url: "/org/get",
        params: {"orgId": orgId}
      }).then(response => {
        this.orgData.push(response.data.datas.organization);
        this.$axios({
          method: "get",
          url: "/parent/getListByOrg",
          params: {
            "orgId": orgId,
            "parentsName": this.parents.parentsName,
            "identity": "终身学习导师",
            "pageSize": this.pageSize,
            "pageNum": this.pageNum
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.parentsData = response.data.datas.parentsPageInfo.list
            // 修改表格中的orgId为orgName的值
            for (let i = 0; i < this.parentsData.length; i++) {
              for (let j = 0; j < this.orgData.length; j++) {
                if (this.parentsData[i].orgId === this.orgData[j].orgId) {
                  this.parentsData[i].orgName = this.orgData[j].orgName
                }
              }
            }
            this.totalCount = response.data.datas.parentsPageInfo.total
          } else {
            this.$message.error("未搜索到相关数据");
          }
        })


      })
    } else {
      this.$axios({
        method: "get",
        url: "/org/getList",
        params: {"orgName": '', "pageSize": -1, "pageNum": 0}
      }).then(response => {
        this.orgData = response.data.datas.organizationList
        this.$axios({
          method: "get",
          url: "/parent/getListByOrg",
          params: {
            "orgId": this.orgId,
            "parentsName": this.parents.parentsName,
            "identity": "终身学习导师",
            "pageSize": this.pageSize,
            "pageNum": this.pageNum
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.parentsData = response.data.datas.parentsPageInfo.list
            // 修改表格中的orgId为orgName的值
            for (let i = 0; i < this.parentsData.length; i++) {
              for (let j = 0; j < this.orgData.length; j++) {
                if (this.parentsData[i].orgId === this.orgData[j].orgId) {
                  this.parentsData[i].orgName = this.orgData[j].orgName
                }
              }
            }
            this.totalCount = response.data.datas.parentsPageInfo.total
          } else {
            this.$message.error("未搜索到相关数据");
          }
        })
      })
    }
  },
  methods: {

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取数据
    getData(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/parent/getListByOrg",
        params: {
          "orgId": this.orgId,
          "parentsName": this.parents.parentsName,
          "identity": "终身学习导师",
          "pageSize": pageSize,
          "pageNum": pageNum
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.parentsData = response.data.datas.parentsPageInfo.list
          // 修改表格中的orgId为orgName的值
          for (let i = 0; i < this.parentsData.length; i++) {
            for (let j = 0; j < this.orgData.length; j++) {
              if (this.parentsData[i].orgId === this.orgData[j].orgId) {
                this.parentsData[i].orgName = this.orgData[j].orgName
              }
            }
          }
          this.totalCount = response.data.datas.parentsPageInfo.total
        } else {
          this.$message.error("未搜索到相关数据");
        }
      })
    },

    // 获取所有的机构下拉选择
    getOrg(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/org/getList",
        params: {"orgName": '', "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        this.orgData = response.data.datas.organizationList
      })
    },

    // 搜索
    handleSearch() {
      this.getData(this.pageSize, this.pageNum)
    },


    showClick(index, row) {
      this.page = false
      this.parents.parentsId = row.parentsId
      this.orgName = row.orgId
      this.showParents(this.pageSize, this.pageNum)
    },

    // 获取数据
    showParents(pageSize, pageNum) {
      this.$axios({
        method: "get",
        url: "/parent/inviter",
        params: {"parentsId": this.parents.parentsId, "pageSize": pageSize, "pageNum": pageNum}
      }).then(response => {
        this.parentsData = response.data.datas.parentsPageInfo.list
        this.totalCount = response.data.datas.parentsPageInfo.total
        // 修改表格中的orgId为orgName的值
        for (let i = 0; i < this.parentsData.length; i++) {
          this.parentsData[i].orgId = this.orgName
        }
      })
    },

    back() {
      this.page = true
      this.reload()
    },

    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(res => {
        if (res == "confirm") {
          this.$axios({
            method: "delete",
            url: "/parent/del",
            params: {"parentsId": row.parentsId}
          }).then(response => {
            if (response.data.code == 200) {
              this.$message.success("删除成功");
              this.reload()
              //this.tableData.splice(department, 1);
            } else {
              this.$message.error("删除失败");
            }
          })
        }
      })
    },

    // 分页
    // 显示第几页
    handleCurrentChange(val) {
      // 切换页码时，要获取每页显示的条数
      if (this.page === true) {
        this.getData(this.pageSize, val)
      } else {
        this.showParents(this.pageSize, val)
      }
    },

  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
